<template>
    <div class="form-inline col-md-4 .col-lg-4 col-xs-12 my-lg-1">
        <input class="form-control  col-12  input-search" v-model="search" v-on:keyup.13="getSearchFor" type="text" placeholder="Buscar" aria-label="Buscar">
    </div>
</template>

<script>

    export default {
        props: ['site'],
        data: ()=>({
            search : '',
        }),
        methods: {
            getSearchFor(){
                // console.log('entrandooo');
                // console.log(this.search);

                 window.location =   '/' + this.site + '/search/'+  this.search;
            },
        },
        beforeMount() {
            if(this.identification == 0){
                this.identification;
            }
        }
    }
</script>
