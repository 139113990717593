<template>
    <div v-if = "this.menuSource.length != 0">

        <JqxMenu ref="menu"
                width="100%"
                 :style="`color: ${ this.color }; background: transparent; width: auto`"
                 :source="menuSource"
                 @itemclick="onItemclick($event)"
                 :height="30">

        </JqxMenu>
    </div>
</template>

<script>
import JqxMenu from "jqwidgets-scripts/jqwidgets-vue/vue_jqxmenu.vue";
import axios from "axios";
export default {
    categories : [],
    components: { JqxMenu },
    props : ['color'],
    name: "MainMenu",
    data: ()=>({
        resource : '/resources/home/menu',
        menuSource : []
    }),
    methods: {
        onItemclick: function (event) {
            console.log(event.args.id);
            if(event.args.id == 0){
                location.href = "/";
            }else{
                location.href = "/category/" + event.args.id;
            }

        },
        loadData() {
            axios.get(this.resource).then(  response => {
                this.categories =   response.data.data;
                console.log(this.categories);
                this.menuSource.push({ label : "Inicio", id : 0 });
                this.categories.forEach(item => {
                    let items = [];
                    item.categories_name.forEach(subItem => {
                        items.push( { label : subItem.name, id :  subItem.id  })
                    });
                    this.menuSource.push({ label : item.name, items : items, id : item.id });
                })

            });

        }
    },
    beforeMount() {
        this.loadData();
    }
}
</script>

<style scoped>
    .jqx-widget-header{
        background: transparent !important;
        border-color:transparent  !important;
        /*color: #f0f4c3 !important;*/
    }
    .jqx-fill-state-pressed{
        background: transparent !important;
    }
    .jqx-fill-state-hover{
        background: transparent !important;
    }
    .jqx-menu-item-top-hover {
        background: transparent !important;
        border-color:transparent  !important;
    }
</style>

