<template>
    <div>
        <v-progress-linear
            indeterminate
            v-if="loading"
        ></v-progress-linear>
        <div class="mt-3 text-white text-center pt-2 pb-2 mb-3 bgTitleSeccion"><h5>{{ this.sectionTitle }}</h5></div>
<!--        <div class="row">
                <product-item   v-for="(product, k) in array_data" :key="k" :product = "product"></product-item>
        </div>-->

            <div  class="row" v-if="array_data.length > 0"  v-bind="settings">
                <product-item :dodir="dodir" v-for="(product, k) in array_data"  :key="k" :product = "product" :client = "client"></product-item>
            </div>
            <div class="d-flex flex-row-reverse" >
                <a class="btn btn-link" v-if="nextPage != null" href="#"  v-on:click="getData(false)">Ver Más</a>
            </div>
        <div class="modal fade sss" id="buttonsModal" tabindex="-1" role="dialog" aria-labelledby="buttonsModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div><h3 class="color_orange text-center">Tu producto ha sido agregado <br/> al carrito de compras</h3></div>
                        <center>
                            <div class="col-md-6 mb-2 mt-1"><button type="button"  class="btn btn-block btn-yellow" border-0 v-on:click="direct('/cart')">Ir al Carrito</button></div>
                            <div class="col-md-6"><button type="button"  v-on:click="direct('/index')" class="btn btn-block btn-blue-skin" border-0  >Agregar más productos</button></div>
                        </center>
                    </div>
                    <div class="modal-footer">

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
import VueSlickCarousel from "vue-slick-carousel";
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
    name: "ProductsSection",
    props : ["title", "url", "client", "dodir"],
    components: { VueSlickCarousel },

  data: ()=>({
        resource : '',
        sectionTitle : '',
        array_data :  [],
        props : {},
        nextPage: null,
        loading : false,
        propsProduct : '',
        checkedCategories: '',
        settings: {
            "dots": false,
            "focusOnSelect": true,
            "infinite": false,
            "speed": 500,
            "slidesToShow": 4,
        },
    }),
    methods: {
        direct: function (linkdirect) {
            window.location = "/" + this.client + linkdirect;
        },
        getData( loadPage = true ){
            this.loading = true;
            axios.get(loadPage ? this.resource : this.nextPage).then( response => {
                console.log('entra');
                if(loadPage) this.array_data = response.data.data;
                    else this.array_data = this.array_data.concat(response.data.data);

                this.nextPage = response.data.links.next;
                console.log("--this.nextPage--",this.nextPage);
                //this.props = this.array_data[0]['category']['more']['props'];
                for(let prop of this.array_data[0]['category']['more']['props']){
                    for(let product of this.array_data ) {
                        for(let propProduct of product.more.caracteristicas) {
                            if(prop == propProduct.prop) {
                                if(this.propsProduct.indexOf(propProduct.value) == -1) {
                                    this.propsProduct += propProduct.value + ',';
                                }
                            }
                           // this.props=  this.props.push(propProduct);
                        }
                    }
                    this.props[prop]=this.propsProduct.split(',');
                    this.propsProduct="";

                }
                this.loading = false;
            });
        },

        searchProductsByProps(valueChecked) {
            axios.post('/get-props-prducts', {
                props: valueChecked
            }).then(response => {
                //this.relatedProducts = response.data.data;
            })
            .catch(function (error) {
                console.log(error);

            });
        }

    },


    beforeMount() {
        this.resource = this.url;
        this.sectionTitle = this.title;
        this.getData();



    }
}
</script>

<style>

.slick-prev:before, .slick-next:before {
    color: #545b62 !important;
}
</style>
