<template>
    <div class="mx-auto">
    <a class="nav-link" data-toggle="modal" data-target="#quotaModal" href="#">Mi Cupo <span class="sr-only">(current)</span></a>

    <div class="modal fade" id="quotaModal" tabindex="-1" role="dialog" aria-labelledby="quotaModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-body">
                    <form>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="pl-4 pr-4 mb-2">
                        <h4 class="modal-title text-center pt-2 pb-1 " id="quotaModalLabel">Digita tu cédula</h4>

                        <div class="form-group">
                            <input type="text" class="form-control" width="400" v-model="identification">
                              <center>
                            <div class="msgWritteIdentification">Por favor escribe el número de identificación</div>

                            <button type="button" class="btn text-center btn-blue-quota mt-2" v-on:click="showPhone" >Continuar</button>
                        </center>
                        </div>

                        <div class="form-group divPhone">
                            <h5 class="modal-title text-center pt-2 pb-1 " id="quotaModalLabel">Digita tu Número de celular terminado en {{this.phoneValidate}}</h5>
                            <input type="text" class="form-control" width="400" v-model="cellphone">
                              <center>
                            <div class="msgWritteCellphone">Por favor escribe el número de celular</div>
                            <button type="button" class="btn text-center btn-blue-quota mt-2" v-on:click="getQuotaUser" >Ver cupo</button>
                        </center>
                        </div>

                        <div class="form-group divEmail">
                            <h4 class="modal-title text-center pt-2 pb-1 " id="quotaModalLabel">Digita tu Correo electronico  {{this.emailValidateInit}} .... {{this.emailValidateEnd}}</h4>
                            <input type="text" class="form-control" width="400" v-model="email">
                            <div class="msgWritteEmail">Por favor escribe el correo electrónico</div>

                              <center>
                            <button type="button" class="btn text-center btn-blue-quota mt-2" v-on:click="getQuota" >Ver Cupo</button>
                        </center>
                        </div>
                        <center>
                            <div id="showQuota" class="pt-2">
                                 Tienes cupo <br/> <b>aprobado</b> de:
                                <div class="quota">${{ quota  | numFormat }}</div>
                            </div>
                            <div id="errorQuota" class="errorQuota">Datos erroneos</div>

                        </center>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    import numeral from 'numeral';
    require('numeral/locales/es');
    numeral.locale('es');
    import numFormat from 'vue-filter-number-format';

    export default {
        filters : {
            numFormat: numFormat(numeral)
        },
        name: 'Quota',
        props: ['idClient'],
        data: ()=>({
            quota : 0,
            identification: '',
            cellphone:'',
            email:'',
            phoneValidate: '',
            emailValidateInit: '',
            emailValidateEnd: '',
        }),
        methods: {
            showPhone(){
                console.log('client', this.idClient);

                if(this.identification== ''){
                    $('.msgWritteIdentification').css('display','block');

                } else {
                    axios.post('/get-customer', {
                        identification: this.identification,
                        idClient: this.idClient
                    })
                        .then(response => {
                        console.log(this.identification);
                        this.customerNow = response.data.data;
                        console.log(response.data.data);

                        $('.divPhone').css('display','block');
                        $('.msgWritteIdentification').css('display','none');
                        this.phoneValidate= response.data.data.phone.substr(-3);
                        this.emailValidateInit= response.data.data.email.substr(0, 3);
                        this.emailValidateEnd= response.data.data.email.substr(-8);

                    })
                    .catch(function (error) {
                        console.log(error);
                        alert('Este numero de cédula no existe');
                    });
                }

            },
            showEmail(){
                if(this.cellphone== ''){
                    $('.msgWritteCellphone').css('display','block');

                } else {
                    $('.divEmail').css('display','block');
                    $('.msgWritteCellphone').css('display','none');

                }
            },
            getQuota() {
                if(this.email == '') {
                    $('.msgWritteEmail').css('display','block');
                } else {
                    $('.msgWritteEmail').css('display','none');

                    axios.get('/quota-user/'+ this.identification + '/' + this.cellphone + '/'+ this.idClient).then(response => {
                            this.quota = '$'+response.data.data.quota;
                            $("#errorQuota").css("display", "none");
                            $("#showQuota").css("display", "block");
                        })
                        .catch(function (error) {
                            console.log('entro00');
                            console.log(error);
                            $("#showQuota").css("display", "none");
                           $("#errorQuota").css("display", "block");
                        });
                    }

            },
            getQuotaUser() {
                if(this.cellphone == '') {
                    $('.msgWritteCellphone').css('display','block');
                } else {
                    $('.msgWritteCellphone').css('display','none');

                    axios.get('/quota-user/'+ this.identification + '/' + this.cellphone+ '/'+ this.idClient).then(response => {
                        console.log('quota user', response.data );
                        this.quota = '$'+response.data.quota;
                        $("#errorQuota").css("display", "none");
                        $("#showQuota").css("display", "block");
                    })
                        .catch(function (error) {
                            console.log(error);
                            $("#showQuota").css("display", "none");
                            $("#errorQuota").css("display", "block");
                        });
                }

            }
        },
        beforeMount() {
            if(this.identification == 0){
                this.identification;
            }
            $("#showQuota").css("display", "none");

            console.log('Quota mounted.')
        }
    }
</script>
