import Vue from 'vue';
import Vuetify from "vuetify";
import ProductsSection from './components/ProductsSection';
import ProductsSectionCategory from './components/ProductsSectionCategory';
import MainMenu from './components/MainMenu';
import ProductItem from './components/ProductItem';
import Quota from './components/Quota';
import Search from './components/Search';
import InitSession from './components/InitSession';
import CloseSession from './components/CloseSession';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import {default_controller} from './helpers';
import {opts_vuefity} from './helpers';
import SlideCategory from "./components/SlideCategory";

Vue.component('SlideCategory', SlideCategory);
Vue.component('ProductItem', ProductItem);
Vue.component('MainMenu', MainMenu);
Vue.component('ProductsSection', ProductsSection);
Vue.component('ProductsSectionCategory', ProductsSectionCategory);
Vue.component('Quota', Quota);
Vue.component('Search', Search);
Vue.component('InitSession', InitSession);
Vue.component('CloseSession', CloseSession);

Vue.use(Vuetify)

new Vue ({
    el: '#app',
    vuetify: new Vuetify(opts_vuefity),
    data:  ()=>({
        controller_recommended: default_controller,
        key: null, 
        showDesigned : false,
        showBought : false
    }),
    methods: {

        getData(){
           // this.controller_recommended.http.get();
        },
    },
    beforeMount() {
        console.log('entra home---------');
        this.controller_recommended.init("products", [ ]);
        this.getData();
    }
})


