<template>

    <div class="mx-auto">
        <a class="nav-link" data-toggle="modal" data-target="#quotaModal" href="#" v-on:click="closeSession()">Cerrar sesión <span class="sr-only">(current)</span></a>
    </div>
</template>

<script>
export default {
    name: "CloseSession",
    data: ()=>({
        identification: '',
        cellphone:'',
        userExist: 1,
        showtxtcode:0,
        codeVerification:0,
        writtenCode: 0,
        correctCode: 0
    }),
    methods: {
        closeSession() {
            if(this.codeVerification == this.writtenCode) {
                this.correctCode = 1;
                axios.post('/logout-customer', {

                })
                    .then(response => {
                        console.log(response);
                        if(response.data.success == false) {
                            console.log('error');
                        } else {
                            window.location = '/index';
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
    }
}
</script>

