<template>

    <div class="mx-auto">
        <a class="nav-link" data-toggle="modal" data-target="#quotaModal" href="#">Iniciar sesión <span class="sr-only">(current)</span></a>

        <div class="modal fade" id="quotaModal" tabindex="-1" role="dialog" aria-labelledby="quotaModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">


                    <div class="modal-body">
                        <div class="pl-4 pr-4 mb-2">
                            <div v-if="dataSession == 0">
                                <div v-if="userExist == 0" class="msgError">Datos incorrectos</div>

                                <h4 class="modal-title text-center pt-2 pb-1 " >Digita tu cédula</h4>

                                <div class="form-group">
                                    <input type="text" class="form-control" width="400" v-model="identification">
                                    <center>
                                        <div class="msgWritteIdentification">Por favor escribe el número de identificación</div>

                                    </center>
                                </div>

                                <div class="form-group">
                                    <h5 class="modal-title text-center pt-2 pb-1 " id="">Digita tu Número de celular</h5>
                                    <input type="text" class="form-control" width="400" v-model="cellphone">
                                    <center>
                                        <div class="msgWritteCellphone">Por favor escribe el número de celular</div>
                                        <button type="button" class="btn text-center btn-blue-quota mt-2" v-on:click="sendMessageUser()" >Enviar</button>
                                    </center>
                                </div>

                            </div>


                            <div class="codenocorrect" v-if="dataSession == 1">
                                <div class="showmessage" v-if="showtxtcode == 1">
                                    <div class="text-danger" v-if="correctCode == 0">Codigo incorrecto</div>
                                    <div class="form-group">
                                        <div class="modal-title text-center" id="buyModalLabelCode">Se ha enviado un código a tu celular. <br/> Digítalo en la casilla y haz clic en confirmar.</div>
                                        <input type="text" class="form-control" id="writtenCode" v-model="writtenCode" >
                                        <center>
                                            <button type="button" class="btn text-center btn-blue-quota mt-2" v-on:click="validateCode()" >Enviar</button>
                                        </center>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

const { createHash } = require('crypto');

export default {
name: "InitSession",
    data: ()=>({
        identification: '',
        cellphone:'',
        userExist: 1,
        showtxtcode:0,
        codeVerification:0,
        writtenCode: '',
        correctCode: 1,
        dataSession: 0,
    }),
    methods: {
        sendMessageUser() {

            if (this.identification == '') {
                this.userExist = 0;
            } else if (this.cellphone == '') {
                this.userExist = 0;
            }
            else {

                axios.post('/send-sms-customer', {
                    identification: this.identification,
                    cellphone: this.cellphone

                })
                    .then(response => {
                        console.log(response);

                        console.log(response.data.success);
                        if(response.data.success == false) {
                            this.userExist = 0;
                            this.dataSession = 0;
                        } else {
                            this.showtxtcode = 1;
                            this.codeVerification = response.data.data.code;
                            this.userExist = 1;
                            this.dataSession = 1;
                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                        this.dataSession = 0;
                        //alert('Este numero de cédula no existe');
                    });
            }

        },
        hash(string) {
            return createHash('sha256').update(string).digest('hex');
        },
        validateCode() {
            console.log('codeVerification', this.codeVerification);
            console.log('writtenCode', this.writtenCode);

            console.log('hashCode', this.hash(this.writtenCode));
            //this.writtenCode;
            if(this.codeVerification == this.hash(this.writtenCode)) {
                this.correctCode = 1;
                axios.post('/authenticate', {
                    identification: this.identification,
                    cellphone: this.cellphone,
                })
                .then(response => {
                    console.log('response', response);
                    if(response.data.success == false) {
                        console.log('error');
                    } else {
                        window.location = '/index';
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            } else {
                this.correctCode = 0;
            }

        },
    }
}
</script>

<style scoped>
.msgError {
    color: red;
}
</style>
