<template>
    <div>

        <div class="mt-3 text-white text-center pt-2 pb-2  bgTitleSeccion"><h5>{{ this.sectionTitle }}</h5></div>
        <div class="row ml-1 mt-3">
            <div class="col-md-2 col-sm-12 leftCategory">
                <vsa-list :auto-collapse="false" >
                    <div class="titleFilter"><h5>Filtrar por:</h5></div>
                    <vsa-item >
                        <vsa-heading>
                            <div :style="`color: ${ colorCategories }; background: transparent;`" class="propProduct">Precio</div>
                        </vsa-heading>
                        <vsa-content>
                            <div style="padding:20px;"  >
                                Mínimo <input style = "border:solid 1px;padding:3px;text-align:right;width:100%;" name="text" type="text" v-model="minPrice" placeholder = "Mínimo"   @change="searchProductsByProps()" >
                                Máximo <input style = "border:solid 1px;padding:3px;text-align:right;width:100%;" name="text" type="text" v-model="maxPrice" placeholder = "Máximo"   @change="searchProductsByProps()" >
                            </div>
                        </vsa-content>
                    </vsa-item>
                    <vsa-item  v-bind:key = "1001010101010">
                        <vsa-heading>
                            <div :style="`color: ${ colorCategories }; background: transparent;`" class="propProduct">Marca</div>
                        </vsa-heading>
                        <vsa-content>

                            <input name="mark" type="radio" :value="undefined" v-model="checkedBrands" :id="undefined" @change="searchProductsByProps()" > Todas
                            <div v-for="mark in brands" v-bind:key = "mark"  >
                                <input  name="mark" type="radio" :value="mark.mark" v-model="checkedBrands" :id="mark" @change="searchProductsByProps()" >
                                {{mark.mark}}
                            </div>
                        </vsa-content>
                    </vsa-item>

                    <vsa-item  v-for="(prop, key) in props"  :key="key" :on-heading-click="onHeadingClick">
                        <vsa-heading>
                            <div :style="`color: ${ colorCategories }; background: transparent;`" class="propProduct">{{key}}</div>
                        </vsa-heading>
                        <vsa-content>
                            <div  v-for="(value, key) in prop" v-bind:key = "key" v-if="value && value != 'null'">
                                <input type="checkbox" :value="value" :id="value"  v-model="checkedCategories"  @change="searchProductsByProps()">
                                {{value}}
                            </div>

                        </vsa-content>
                    </vsa-item>

                </vsa-list>

            </div>
            <div class="row col-md-10">
                <product-item :dodir = "dodir" :client = "client" v-for="(product, k) in array_data"  :key="k" :product = "product"></product-item>
            </div>

        </div>
        <div class="modal fade sss" id="buttonsModal" tabindex="-1" role="dialog" aria-labelledby="buttonsModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div><h3 class="color_orange text-center">Tu producto ha sido agregado <br/> al carrito de compras</h3></div>
                        <center>
                            <div class="col-md-6 mb-2 mt-1"><button type="button"  class="btn btn-block btn-yellow" border-0 v-on:click="direct('/cart')">Ir al Carrito</button></div>
                            <div class="col-md-6"><button type="button"  v-on:click="direct('/index')" class="btn btn-block btn-blue-skin" border-0  >Agregar más productos</button></div>
                        </center>
                    </div>
                    <div class="modal-footer">

                    </div>
                </div>
            </div>
        </div>
        <div class="loadPage" v-if="loadPage == true">sss </div>
    </div>
</template>

<script>
import axios from 'axios';
import {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

export default {
    name: "ProductsSectionCategory",
    props : ["title", "url", "category", "color", "client" , "dodir"],
    components: {
        VsaList,
        VsaItem,
        VsaHeading,
        VsaContent,
        VsaIcon
    },
    data: ()=>({
        checkedBrands : undefined,
        minPrice : 0,
        maxPrice: 0,
        resource : '',
        sectionTitle : '',
        array_data :  [],
        props : {},
        brands: null,
        propsProduct : '',
        checkedCategories: [],
        colorCategories: '',
        checkCategories: [],
        loadPage: false
    }),

    methods: {
        direct: function (linkdirect) {
            window.location = "/" + this.client + linkdirect;
        },
        onHeadingClick(){},

        getData(){
            axios.get(this.resource).then( response => {
                this.array_data = response.data.data;
                console.log(this.array_data);
                //this.props = this.array_data[0]['category']['more']['props'];
                let marks = [];
                let i = 0;
                for(let product of this.array_data ) {
                    console.log(marks.includes(product.mark));
                    //console.log(marks.product.includes(product.mark));
                    const resultFindMark = marks.find( mark => mark.mark === product.mark);
                    console.log('<<<<<resultado>>>>>');
                    console.log(resultFindMark);

                    if (resultFindMark == undefined) {
                        marks.push({id: i, mark: product.mark});
                        i++;
                    }
                }
                let propsFilter = [];
                console.log("this.array_data[0]['category']['more']");
                console.log(this.array_data[0]);

                if(this.array_data[0] != undefined ) {
                    if(this.array_data[0]['category']['more']['filterProps'] != undefined) {
                        propsFilter = this.array_data[0]['category']['more']['filterProps'];
                    } else if(this.array_data[0]['category']['more']['props'] != undefined) {
                        propsFilter = this.array_data[0]['category']['more']['props'];
                    }
                }



                for(let prop of propsFilter){
                    for(let product of this.array_data ) {

                        for(let propProduct of product.more.caracteristicas) {

                            if(prop == propProduct.prop) {
                                if(this.propsProduct.indexOf(propProduct.value) == -1) {
                                    this.propsProduct += propProduct.value + ',';
                                }
                            }

                            // this.props=  this.props.push(propProduct);
                        }
                    }

                    this.propsProduct = this.propsProduct.substring(0, this.propsProduct.length - 1);
                    console.log('<<<<<<<prop>>>>>>>');
                    console.log(prop);
                    console.log(this.propsProduct);
                    console.log('<<<<<<<prop>>>>>>>');

                    if(this.propsProduct != 'null') {
                        this.props[prop] = this.propsProduct.split(',');
                    }


                    this.propsProduct = "";
                }

                this.loadPage = false;
                this.brands = marks;
            });
        },

        searchProductsByProps() {
            this.loadPage = true;
            this.array_data = [];
            let strCategroy = this.checkedCategories.toString();

            if(this.minPrice==""){ this.minPrice=0; }
            if(this.maxPrice==""){ this.maxPrice=0; }

            if(strCategroy != "" || this.checkedBrands || (this.maxPrice != 0 && this.minPrice != 0) ) {
                console.log("checkedBrands", this.checkedBrands);
                if(strCategroy==""){
                    strCategroy="-";
                }
                axios.get('/resources/home/category-products/' + this.categoryId + '/' + strCategroy + '?brand=' + this.checkedBrands + "&min-price="+this.minPrice+"&max-price="+this.maxPrice).then( response => {
                    this.array_data = response.data.data;
                    console.log('arrdata');
                    console.log(this.array_data);
                    //this.props = this.array_data[0]['category']['more']['props'];
                    console.log(this.props)
                    this.loadPage = false;
                });
            } else {
                this.getData();
            }
        }
    },
    beforeMount() {
        this.resource = this.url;
        this.categoryId = this.category;
        this.sectionTitle = this.title;
        this.getData();
        console.log(this.url);
        this.colorCategories = this.color;

    }
}
</script>

<style scoped>
.propProduct {
    padding-top: 15px;
    padding-bottom: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;

}
.leftCategory {
    margin: 0;
    padding: 0;
}
.loadPage {
    width: 100%;
    min-height: 100%;
    height: auto !important;
    position: fixed;
    top: 0;
    left: 0;
    align-content: center;
    text-align: center;
    opacity: 0.5;
    background-image: url("/assets/images/load.gif");
    background-position: center center;
    background-color: #fff;

}
.span.vsa-item__trigger__content {
    font-size:  0.8rem !important;
}
.vsa-item__heading {
    padding-left: 15px;
}
.vsa-list {
    --vsa-max-width: 320px;
    --vsa-min-width: 200px;
    --vsa-text-color: rgba(55, 55, 55, 1);
    --vsa-highlight-color: rgba(85, 119, 170, 1);
    --vsa-bg-color: rgba(255, 255, 255, 1);
    --vsa-border-color: rgba(0, 0, 0, 0.2);
    --vsa-border-width: 1px;
    --vsa-border-style: solid;
    --vsa-heading-padding: 0.5rem 0.5rem;
    --vsa-content-padding: 1rem 1rem;
    --vsa-default-icon-size: 0.5;
}
.titleFilter {
    margin: 15px 10px 2px 15px;
}

</style>
