<template>
    <div>
        <!--<VueSlickCarousel  v-if="images.length > 0"  v-bind="settings"
        >
            <div v-for="thumbs in images">
                <div v-if="thumbs.link != '' && thumbs.link != null && thumbs.link != 'null'">
                    <a v-if="thumbs.target==0" :href="thumbs.link" target="_self">
                        <img :src="image"/>
                    </a>
                    <a v-if="thumbs.target==1" :href="thumbs.link" target="_blank">
                        <img :src="image"/>
                    </a>
                </div>
                <div v-else >
                    <img :src="image"/>
                </div>


            </div>
        </VueSlickCarousel>-->
        <img :src="image" style="width: 100%;height: auto;"/>
    </div>
</template>

<script>
require('numeral/locales/es');
import numFormat from 'vue-filter-number-format';
import {default_controller} from '../helpers';
import numeral from 'numeral';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
    name: 'SlideCategory',
    props: ['categoryId', 'dodir'],
    components: { VueSlickCarousel },


    data: ()=>({
        controller : default_controller,
        image: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDAAoHBwgHBgoICAgLCgoLDhgQDg0NDh0VFhEYIx8lJCIfIiEmKzcvJik0KSEiMEExNDk7Pj4+JS5ESUM8SDc9Pjv/2wBDAQoLCw4NDhwQEBw7KCIoOzs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozv/wAARCAADB2wDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAf/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/ALMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD//Z",
        errors:[],
        id : 0,
        identification: 0,
        productCart: [],
        quantity: 1,
        idBuy: 123,
        writtenCode: 0,
        codeVerification: 0,
        localId : 0,
        description: [],
        category: {},
        images: {},
        j: 0,
        settings: {
            "dots": false,
            "focusOnSelect": true,
            "infinite": false,
            "speed": 500,
        },
        key: 0
    }),
    methods: {
        getImageData(id,size) {
                const url = `https://ikommi-images.nyc3.digitaloceanspaces.com/${this.dodir}/${id}_${size}.png`;
                return axios
                    .get(url, {
                    responseType: 'arraybuffer'
                })
                .then(response => Buffer.from(response.data, 'binary').toString('base64'))
            },
            getLocalImage(id, size){
                const url = '/resources/images/' + id + '?width=' + size;
                return axios
                    .get(url, {
                    responseType: 'arraybuffer'
                })
                .then(response => Buffer.from(response.data, 'binary').toString('base64'))
            },
        getCategory (){
            //this.controller.http.show(this.id);
            axios.post('/get-category', {
                category_id: this.id
            }).then(response => {
                this.category = response.data;
                this.images = response.data.images;
            })
                .catch(function (error) {
                    console.log(error);

                });
        },
    },

    async beforeMount() {
        console.log('Component mounted.')
        this.controller.init("categories", [ ]);
        this.id = this.categoryId;
        this.getCategory();
        console.log('idcategoru');
        console.log(this.id);

        let image_content;
            try{
                image_content =  await this.getImageData(this.id, "");
                this.image =  "data:image/jpeg;base64," + image_content;
            }catch(err){
                console.log(err);
                // image_content = await this.getLocalImage(this.id, "");
            }

    },
}
</script>
<style scoped>


</style>
